/**
 * File library.js
 * 
 * Custom scripts.
 */

// Variables
const page = document.getElementById( 'page' );
const header = document.getElementById( 'masthead' );
const headerHeight = header.offsetHeight;

const menuOffCanvas = document.getElementById( 'menu-offcanvas' );
const openMenu = document.getElementById( 'open-menu' );
const closeMenu = document.getElementById( 'close-menu' );

const siteNavigation = document.getElementById( 'site-navigation' );
const menu = siteNavigation.getElementsByTagName( 'ul' )[0];

const sectionMenu = document.getElementById( 'section-choice-menu' );

if ( document.body.classList.contains( 'is-desktop' ) ) {
	// Adjust page top padding for fixed header
	page.style.paddingTop = headerHeight + "px";

	// Toggle class and top position for header on scroll
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		if ( prevScrollpos > currentScrollPos ) {
			header.style.top = "0";
			header.classList.add( 'moved' );
		} else {
			header.style.top = "-" + headerHeight + "px";
		}

		if ( currentScrollPos == 0 ) {
			header.classList.remove( 'moved' );
		}

		prevScrollpos = currentScrollPos;
	}

	// Toggle classes for pseudo-select languages
	const languages = document.getElementsByClassName( 'languages' )[0];
	let langSelect = languages.getElementsByClassName( 'select' )[0];
	let langOption = languages.getElementsByClassName( 'option' );

	langSelect.addEventListener( 'click', function(event) {
		event.preventDefault();
		langSelect.classList.toggle( 'active' );

		for ( var i = 0; i < langOption.length; i++ ) {
			langOption[i].classList.toggle( 'open' );
		}
	});
}

if ( document.body.classList.contains( 'is-mobile' ) ) {
	// Adjust footer bottom margin for fixed toolbar
	toolbarHeight = document.getElementById( 'toolbar' ).offsetHeight;
	page.style.marginBottom = toolbarHeight + "px";
}

if ( document.body.classList.contains( 'no-image' ) ) {
	// Adjust page top padding for header
	page.style.paddingTop = headerHeight + "px";
}

// Toggle class for off canvas menu on click
if ( openMenu ) {
	openMenu.addEventListener( 'click', function() {
		menuOffCanvas.classList.add( 'offcanvas-open' );
	} );
}

if ( closeMenu ) {
	closeMenu.addEventListener( 'click', function() {
		menuOffCanvas.classList.remove( 'offcanvas-open' );
	} );
}

// Inhibit hashtag links
const links = document.querySelectorAll( 'a[href="#"]' );

for ( var i = 0; i < links.length; i++ ) {
	links[i].addEventListener( 'click', function(event) {
		event.preventDefault();
	} );
}

// Manage navigation menu with respect to the device
if ( menu.querySelectorAll('.menu-item-has-children').length > 0 ) {
	const menuItems = menu.getElementsByClassName( 'menu-item-has-children' );

	if ( document.body.classList.contains( 'is-mobile' ) ) {
		const allSubmenu = menu.getElementsByClassName( 'sub-menu' );

		for ( var i = 0; i < menuItems.length; i++ ) {
			let submenu = menuItems[i].getElementsByTagName( 'ul' )[0];

			menuItems[i].addEventListener( 'click', function() {
				for ( var j = 0; j < allSubmenu.length; j++ ) {
					allSubmenu[j].classList.remove( 'open' );
				}

				submenu.classList.toggle( 'open' );
			} );
		}
	} else {
		for (var i = 0; i < menuItems.length; i++) {
			let submenu = menuItems[i].getElementsByTagName( 'ul' )[0];
			let contacts = menuItems[i].getElementsByClassName( 'menu-contacts' )[0];

			menuItems[i].addEventListener( 'mouseover', function() {
				submenu.classList.add( 'open' );
				contacts.classList.add( 'open' );
			} );

			menuItems[i].addEventListener( 'mouseout', function() {
				submenu.classList.remove( 'open' );
				contacts.classList.remove( 'open' );
			} );
		}
	}
}

// Adjust top position for solutions
var solutions = document.getElementById( 'soluzioni' );

if ( solutions ) {
	if ( window.matchMedia( 'screen and (min-width: 768px)' ).matches ) {
		var typeSolution = solutions.getElementsByClassName( 'type' );
		var corrective = 80;

		for ( var i = 0; i < typeSolution.length; i++ ) {
			let width = typeSolution[i].clientWidth;

			typeSolution[i].style.top = width + corrective + "px";
		}
	}
}

// Open tab content
function openTab(evt, context, tabId) {
	var i, tabpanel, tabbutton;

	// Get all elements with class="tab-panel" and hide them
	tabpanel = document.getElementById(context).getElementsByClassName( 'tab-panel' );
	for ( i = 0; i < tabpanel.length; i++ ) {
		tabpanel[i].style.display = 'none';
	}
	
	// Get all elements with class="tab-button" and remove the class "active"
	tabbutton = document.getElementById(context).getElementsByClassName( 'tab-button' );
	for ( i = 0; i < tabbutton.length; i++ ) {
		tabbutton[i].className = tabbutton[i].className.replace( ' active', '' );
	}
	
	// Show the current tab, and add an "active" class to the link that opened the tab
	document.getElementById(tabId).style.display = 'block';
	evt.currentTarget.className += ' active';
}

if ( document.getElementById( 'defaultActive' ) ) {
	document.getElementById( 'defaultActive' ).click();
}

if ( document.getElementById( 'defaultWebinarActive' ) ) {
	document.getElementById( 'defaultWebinarActive' ).click();
}

// Add element for wrap (no smartphones)
if ( document.getElementById( 'choice' ) ) {
	if ( window.matchMedia( 'screen and (min-width: 768px)' ).matches ) {
		var lifirst = sectionMenu.firstChild.insertAdjacentHTML( 'afterend', '<div class="w-75"></div>' );
	}
}

// News slider
if ( document.getElementsByClassName( 'news-slider' ).length > 0 ) {
	var nslider = tns({
		container: '.news-slider',
		items: 1,
		controls: false,
		navPosition: 'bottom',
		gutter: 20,
		edgePadding: 10,
		responsive: {
			768: {
				items: 3,
				gutter: 30,
				edgePadding: 15
			},
			1200: {
				gutter: 120,
				edgePadding: 60
			}
		}
	});
}

// Set same height to elements with specific class
window.addEventListener('load', function() {
	Equalizer.init();
});

// Search, filter and paginate news
if ( document.getElementById( 'news-list' ) ) {
	var items = document.body.classList.contains( 'is-mobile' ) ? 6 : 9;
	var select = document.getElementById( 'news-filters' );
	var wrapper = document.getElementById( 'pagination-wrapper' );

	var options = {
		valueNames: [ 'title', 'content', 'category' ],
		page: items,
		pagination: true
	};

	var newsList = new List('news-list', options);

	function updateList() {
		var value = select.value;
		newsList.filter(function(item) {
			var categoryFilter = false;
			if ( value == 'all' ) {
				categoryFilter = true;
			} else {
				categoryFilter = item.values().category.indexOf(value) >= 0;
			}
			return categoryFilter;
		});
		newsList.update();
	}

	if ( select ) {
		select.addEventListener( 'change', updateList );
	}

	if ( wrapper ) {
		wrapper.insertAdjacentHTML( 'afterbegin', '<div class="prev"> &lsaquo; </div>' );
		wrapper.insertAdjacentHTML( 'beforeend', '<div class="next"> &rsaquo; </div>' );

		wrapper.querySelector( '.prev' ).addEventListener( 'click', function() {
			var el = document.querySelector( '.pagination .active' ).previousElementSibling;
			el.click();
		} );

		wrapper.querySelector( '.next' ).addEventListener( 'click', function() {
			var el = document.querySelector( '.pagination .active' ).nextElementSibling;
			el.click();
		} );

		newsList.on( 'updated', function() {
			if ( newsList.matchingItems.length <= items ) {
				wrapper.style.display = 'none';
			} else {
				wrapper.style.display = 'flex';
			}
		} );
	}
}
